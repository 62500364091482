<template>
    <div class="exchangeDetails_page">
        <new-header :config="headerConfig"></new-header>

        <div class="top_tips">
            已兑换：{{ unionOrderTime }}
        </div>

        <div class="list_data">
            <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
                <div class="card" v-for="(item, index) in list" :key="index">
                    <div class="card_img">
                        <img class="img" :src="item.goods_image" alt="">
                    </div>
                    <div class="card_text">
                        <div class="text_1">{{ item.goods_name }}</div>
                        <div class="text_2">订单编号：{{ item.order_sn }}</div>
                        <div class="text_2">时间：{{ item.order_time }}</div>
                        <div class="text_2">订单金额：{{ item.order_amount }}</div>
                    </div>
                    <div class="card_state">
                        <div>
                            <div>{{ item.state_nick }}</div>
                            <div :style="{ color: item.state == 2 ? '#FF4040' : '#0091FF' }">
                                {{ item.exchange_tip }}
                            </div>
                        </div>
                    </div>
                </div>
                <van-empty v-if="emptyShow" description="暂无兑换记录" />
            </vue-loadmore>
        </div>
    </div>
</template>
<script>
import newHeader from "@/components/newHeader";
import { getOrderRead_api } from '@/api/perdata'

export default {
    components: {
        newHeader
    },
    data() {
        return {
            headerConfig: {
                show: true,
                title: '兑换明细',
            },
            unionOrderTime: '99',
            finished: false,
            pages: 0,
            query: { page: 1, limit: 10 },
            list: [],

            emptyShow: false
        }
    },
    created() {
        this.getList()
    },
    methods: {
        onRefresh(done) {
            this.list = [];
            this.query.page = 1;
            this.finished = false;
            this.getList();
            done();
        },
        onLoad(done) {
            if (this.query.page >= this.pages) {
                this.finished = true;
            } else {
                this.fetch();
            }
            done();
        },
        fetch() {
            this.query.page++;
            this.getList();
        },
        async getList() {
            let res = await getOrderRead_api(this.query)
            if (res.code == 0) {
                this.unionOrderTime = res.data.conversionYear
                res.data.list.forEach(item => {
                    item.goods_image = item.orders.order_goods.goods_image
                    item.goods_name = item.orders.order_goods.goods_name
                    item.order_amount = item.orders.order_goods.no_discount_price
                    item.order_time = item.orders.add_time

                    if (item.state == 2) {
                        item.state_nick = '已失效'
                        if (item.orders.order_state == 100 || item.orders.order_state == 0) {
                            item.exchange_tip = '订单关闭'
                        } else {
                            item.exchange_tip = '兑换取消'
                        }
                    } else {
                        item.state_nick = '已兑换'
                        item.exchange_tip = item.conversion_year
                    }
                })
                this.list = this.list.concat(res.data.list)
                this.emptyShow = this.list.length == 0
                this.pages = res.pagination.pages
            }
        }
    }
}

</script>
<style scoped>
.exchangeDetails_page {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 30px 35px;
    background: #F9F9FB;
}

.top_tips {
    font-size: 26px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 30px;
}

.list_data {
    margin-top: 30px;
}

.card {
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    background: linear-gradient(180deg, #C1E4FF 0%, #FFFFFF 50%);
    height: 200px;
    width: 100%;

    box-sizing: border-box;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
}

.card_img {
    margin-right: 15px;
    border-radius: 10px;
}

.img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.card_text {
    font-size: 26px;
    flex: 1;
}

.text_1 {
    font-weight: bold;
    width: 350px;
    color: #1A1A1A;
    margin-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text_2 {
    font-size: 24px;
    line-height: 39px;
    color: rgba(26, 26, 26, 0.8);
}

.card_state {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 26px;
    line-height: 32px;
}
</style>